html {
  background-color: #eaeaea !important;

}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

  /* font-family: url('/src/font/NotoSerifTelugo/NotoSerifTelugu-VariableFont_wght.ttf') !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* overflow-y: hidden !important; */
  /* background: url('shape.svg'); */
  /* background-size: contain;  */
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {

}
