.top {
    width: 100vw;
    /* height: 15px;  */
    /* background-color: white; */

    /* background-size: 200% 200% !important;  */
    /* animation: top-animation 20s ease infinite; */
    /* background: linear-gradient(90deg, rgba(4,246,65,1) 0%, rgb(255, 255, 255) 45%, rgba(4,246,65,1) 55%, rgb(255, 255, 255) 100%);       */
    /* background: linear-gradient(
        90deg,
        rgb(255, 255, 255) 0%,
        rgb(255, 255, 255) 48%,
        rgb(0, 255, 102) 49%,
        rgb(255, 255, 255) 51%,
        rgb(0, 255, 102) 52%,
        rgb(255, 255, 255) 54%,
        rgb(0, 255, 102) 55%,
        rgb(255, 255, 255) 56%,
        rgb(255, 255, 255) 100%
    ); */
    
    /* 400% 400% */
    
    /* background-color: #04F665; */
    /* height: 8px; */
    /* background-color: #eaeaea; */
    /*     background: linear-gradient(
        90deg,
        rgba(4,246,65,1) 0%, 
        rgba(12,194,85,1) 45%, 
        rgb(132, 232, 172) 55%, 
        rgba(4,246,101,1) 100%
    ); */
    /* background-size: 100% !important; */
    /* background: rgb(4,246,65); */
    /* background: linear-gradient(90deg, rgba(4,246,65,1) 0%, rgb(220, 45, 45) 45%, rgb(136, 74, 171) 55%, rgba(4,246,65,1) 100%);       */
    /* animation: gradient 15s ease infinite; */
}

#header {
    z-index: 15 !important;
    position: fixed;
}

.vb-navbar {
    /* background-color: #1e1e1e !important; */
    background-color: #292929 !important;
}

.nav-link {
    /* color: white; */
}

.active-link {
    text-decoration-line: underline;
    text-decoration-thickness: 4px;
    text-underline-offset: 10px;
    text-decoration-color: rgb(4,246,65);
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes top-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


