#footer {
    color: white;
}

.vb-footer-box {
    /* background-color: #212529;
    height: fit-content;
    color: white; */
    background-color: #212529;
}

.vb-table-footer {
    padding-bottom: 50px;
}

.vb-table {
    border-collapse: separate;
    border-spacing: 5px 0;
}

.vb-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}